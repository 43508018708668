<template>
    <div class="header-options">
        <div 
            id="J_header-option-group"    
            class="option-group"
            @mouseenter="handlerMouseenter"
            @mouseleave="handlerMouseleave"
        >
            <div class="img-group">
                <img class="img" :src="imgSrc" alt="img" />
                <div class="hi-group" :style="{right: imgRight + 'px'}">
                    <img v-if="plusTodo == 0" class="hi-img" :src="imgHiSrc" alt="hi">
                    <span v-else-if="plusTodo < 100" class="feedback-ni-group">
                        <img :src="feedbackNi" class="feedback-ni" alt="feedback-ni">
                        <span class="text">{{ plusTodo }}</span>
                    </span>
                    <span v-else-if="plusTodo >= 100" class="feedback-ni-plus-group">
                        <img :src="feedbackNiPlus" class="feedback-ni-plus" alt="feedback-ni-plus">
                        <span class="text">{{ '99+' }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { createdHeaderOptions } from './HeaderOptions/createdHeaderOptions.js';
    import HeaderOptionsList from './HeaderOptions/index.vue';
    import { mapState } from 'vuex';
    export default {
        name: "HeaderOptions",
        components: {
            HeaderOptionsList
        },
        data() {
            return {
                imgWidth: 91,
                imgHeight: 78,
                imgRight: 10,
                placement: {
                    top: '',
                    left: '',
                },
                moveImg: false,
                headerOptionsList: null,
            }
        },
        computed: {
            ...mapState({
                feedbackNum: state => state.feedbackNum,
                dealtNum: (state) => state.dealtNum,
                mode: state => state.style.mode
            }),
            imgSrc() {
                switch (this.mode) {
                    case 'nationalDay':
                        return require('@/assets/images/header/theme/nationalDay.png');
                    case 'spiritualMystery':
                        return require('@/assets/images/header/theme/spiritualMystery.png');
                    default:
                        return require('@/assets/images/header/theme/spiritualMystery.png');
                    break;
                }
            },
            imgHiSrc() {
                switch (this.mode) {
                    case 'nationalDay':
                        return require('@/assets/images/header/theme/hi2.png');
                    case 'spiritualMystery':
                        return require('@/assets/images/header/theme/hi.png');
                    default:
                        return require('@/assets/images/header/theme/hi.png');
                    break;
                }
            },
            feedbackNi() {
                return require('@/assets/images/header/feedback-ni.png')
            },
            feedbackNiPlus() {
                return require('@/assets/images/header/feedback-ni-plus.png')
            },
            plusTodo() {
                return (Number(this.dealtNum) || 0) + (Number(this.feedbackNum) || 0);
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$eventBus.$on("setImgRight", this.setImgRight);
            this.$store.dispatch('getHasNewFeedback')
        },
        methods: {
            init() {
                this.initPageData();
            },
            initPageData() {
                switch (this.mode) {
                    case 'nationalDay':
                        this.imgRight = -10;
                        break;
                    case 'spiritualMystery':
                        this.imgRight = 10;
                        break;
                    default:
                        this.imgRight = 10;
                    break;
                }
            },
            moveImage() {
                switch (this.mode) {
                    case 'nationalDay':
                        this.imgRight = -10;
                        break;
                    case 'spiritualMystery':
                        this.imgRight = 7;
                        break;
                    default:
                        this.imgRight = 7;
                    break;
                }
            },
            /**
             * @Description: 鼠标滑入
             * @Author: 武东超
             * @Date: 2024-08-19 17:24:46
             */            
            handlerMouseenter() {
                this.moveImage();
                const oHeaderOptionGroup = document.querySelector("#J_header-option-group");
                let offsetTop = this.offsetTop(oHeaderOptionGroup) + this.imgHeight;
                let offsetLeft = this.offsetLeft(oHeaderOptionGroup) + this.imgWidth;
                this.placement.top = offsetTop + "px";
                this.placement.left = offsetLeft + "px";
                this.createdHeaderOptionList();
            },
            /**
             * @Description: 获取高度
             * @Author: 武东超
             * @Date: 2024-08-19 17:28:04
             */            
            offsetTop(elements) {
                let top = elements.offsetTop;
                let parent = elements.offsetParent;
                while (parent != null) {
                    top += parent.offsetTop;
                    parent = parent.offsetParent;
                }
                return top;
            },
            /**
             * @Description: 获取左侧距离
             * @Author: 武东超
             * @Date: 2024-08-19 17:28:16
             */            
             offsetLeft(elements) {
                let left = elements.offsetLeft;
                let parent = elements.offsetParent;
                while (parent != null) {
                    left += parent.offsetLeft;
                    parent = parent.offsetParent;
                };
                return left;
            },
            /**
             * @Description: 创建下拉列表
             * @Author: 武东超
             * @Date: 2024-08-19 17:29:58
             */            
            createdHeaderOptionList() {
                this.$store.dispatch('getHasNewFeedback')
                this.headerOptionsList && this.headerOptionsList.close();
                this.headerOptionsList = createdHeaderOptions(HeaderOptionsList, {
                    placement: this.placement
                })
            },
            /**
             * @Description: 鼠标移出
             * @Author: 武东超
             * @Date: 2024-08-19 17:24:29
             */            
            handlerMouseleave(event) {
                const _this = this;
                this.$nextTick(() => {
                    setTimeout(() => {
                        const J_MOUSE = 'J_mouse';
                        const dataValue = event.relatedTarget && event.relatedTarget.getAttribute('data-libs');
                        if (J_MOUSE === dataValue) {
                            return;
                        } else {
                            _this.initPageData();
                            _this.headerOptionsList && _this.headerOptionsList.close()
                        }
                    }, 200)
                })
            },
            /**
             * @Description: 修改hi图片位置
             * @Author: 武东超
             * @Date: 2024-08-20 16:04:31
             */            
            setImgRight() {
                this.initPageData();
            }
        },
        watch: {
            mode: {
                handler() {
                    this.initPageData();
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .header-options {
        margin-right: 5px;
        .option-group {
            position: relative;
            box-sizing: border-box;
            padding-top: 38px;
            .img-group {
                width: 91px;
                cursor: pointer;
                position: relative;
                .img {
                    width: 100%;
                } 

                .hi-group {
                    position: absolute;
                    top: 4px;
                    right: 10px;
                    width: 18px;
                    transition: all 1;
                    .hi-img {
                        width: 100%;
                    }
                    .feedback-ni-group {
                        display: block;
                        width: 26px;
                        position: relative;
                        .feedback-ni {
                            width: 100%;
                        }
                        .text {
                            position: absolute;
                            top: 22%;
                            left: 48%;
                            transform: translate(-50%, -50%);
                            display: block;
                            height: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }

                    .feedback-ni-plus-group {
                        display: block;
                        width: 31px;
                        position: relative;
                        .feedback-ni-plus {
                            width: 100%;
                        }
                        .text {
                            position: absolute;
                            top: 22%;
                            left: 48%;
                            transform: translate(-50%, -50%);
                            display: block;
                            height: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
</style>